<template>
  <div>
    <v-sheet>
      <v-card>
        <v-card-title>Турнирная таблица</v-card-title>
      </v-card>
      <v-simple-table class="mе-4 mb-4 ml-0 mr-0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Место</th>
              <th class="text-left">Команда</th>
              <th class="text-left">Баллов</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in teams" :key="item.id">
              <td>{{ item.index }}</td>
              <td style="text-transform: capitalize">{{ item.id }}</td>
              <td>{{ item.total }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'NoLimitsStandings',
  props: ['items', 'my-points', 'my-game-id', 'params'],
  computed: {
    ...mapGetters({
      meta: 'event/eventMeta',
    }),
    teams() {
      const teamsByIDs = _.reduce(
        this.items,
        (acc, item) => {
          const { tags } = item;
          const teamTag = _.find(tags, (tag) => /команда\s+\d+/gi.test(tag));
          if (teamTag) {
            if (acc[teamTag] == null) {
              acc[teamTag] = {
                items: [],
                quest: 0,
              };
            }
            acc[teamTag].items.push(item);
            acc[teamTag].quest += item.points;
          }
          return acc;
        },
        {}
      );
      const result = _.map(
        _.orderBy(
          Object.keys(teamsByIDs).map((teamKey) => {
            const { items: teamPlayers } = teamsByIDs[teamKey];
            let teamPoints = null;

            if (!this.isAverage) {
              teamPoints = teamPlayers.reduce((acc, item) => {
                return acc + item.team;
              }, 0);
            } else {
              const nonZeroPlayers = _.filter(
                teamPlayers,
                (item) => item.team > 0
              );
              teamPoints =
                nonZeroPlayers.length === 0
                  ? 0
                  : nonZeroPlayers.reduce((acc, item) => acc + item.team, 0) /
                    nonZeroPlayers.length;
            }
            const total = teamPoints;
            const result = {
              id: teamKey,
              total: Math.round(total),
            };
            return result;
          }),
          ['total'],
          ['desc']
        ),
        (item, index) => {
          return {
            ...item,
            index: index + 1,
          };
        }
      );
      return result;
    },
    isAverage() {
      return _.get(this.params, 'standings.average') === true;
    },
  },
};
</script>
